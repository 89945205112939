import React, { useEffect, useMemo, useRef, useState } from "react"
import { Box, Container, Heading, Text } from "@chakra-ui/react"

import { useProductContext } from "@app/providers/product"
import { useAppContext } from "@app/providers/app"
import { useShopify } from "@app/hooks/useShopify"
import { useMedia } from "@app/hooks/useMedia"
import { AnalyticProps, withSection } from "@app/hoc/Section"
import ProductContentsSwiper from "@app/components/Product/ProductContentsSwiper"
import ProductContentsGrid from "@app/components/Product/ProductContentsGrid"

import type { ProductTemplateSectionProps } from "@app/components/Product/Product"
import type { NormalisedProduct } from "@root/types/custom-types/Product/Product"

type Props = GatsbyTypes.SanitySectionProductContents & AnalyticProps & ProductTemplateSectionProps

const ProductContents: React.FC<Props> = ({ menuTitle, title, description, sanityProduct, innerRef, handleTrackingClick, tag }) => {
  const { getProducts, getHandle } = useShopify()
  const { isBase, isLarge, isXLarge } = useMedia()
  const { saveProductItems } = useAppContext()
  const { productBundle } = useProductContext()

  const sectionRef = useRef<HTMLDivElement>(null)

  const [products, setProducts] = useState<NormalisedProduct[]>([])

  useEffect(() => saveProductItems(menuTitle, sectionRef), [sectionRef, saveProductItems, menuTitle])

  const sanityProducts = useMemo(() => sanityProduct?.includedProducts as GatsbyTypes.SanityProduct[], [sanityProduct])

  const fetchItems = useMemo(
    () => async () => {
      if (!sanityProducts.length) return

      const sanityHandles = sanityProducts.map((product: any) => getHandle(product))
      const handles = productBundle.step5?.length ? [productBundle.step5[0].handle, ...sanityHandles] : sanityHandles
      const results = await getProducts({ firstVariants: 1, firstImages: 1, handles: handles })
      if (results.length) setProducts(results)
    },
    [getProducts, sanityProducts, getHandle, setProducts, productBundle.step5]
  )

  let componentType = "default"
  if (!isLarge && !isXLarge) componentType = products.length > 3 ? "swiper" : "default"
  if (!isBase && !isXLarge) componentType = products.length > 4 ? "swiper" : "default"
  if (isXLarge) componentType = products.length > 6 ? "swiper" : "default"

  // intentionally only run once at first render
  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return sanityProducts.length ? (
    <Box as="section" bg="background.grey100" ref={sectionRef}>
      <Container ref={innerRef} maxW="container.2xl" px={{ base: "0", lg: "20" }} pt={{ lg: "18" }} pb={{ lg: "7.5" }}>
        <Box py={{ base: "8", lg: "12" }} px={{ lg: "10" }} bg="background.white" borderRadius={{ lg: "xl" }}>
          <Box maxW={{ base: "container.sm", lg: "none" }} px={{ base: "4", lg: "0" }} mb={{ base: "6", lg: "5" }}>
            <Heading as={tag} size="h4" color="typography.headlines900">
              {title}
            </Heading>

            {description ? (
              <Text size="largeParagraph" mt={{ base: "1.5", lg: "3" }}>
                {description}
              </Text>
            ) : null}
          </Box>

          {componentType === "swiper" ? (
            <ProductContentsSwiper
              products={products}
              handleTrackingClick={handleTrackingClick}
              sanityProductsLength={sanityProducts.length}
            />
          ) : (
            <ProductContentsGrid
              products={products}
              handleTrackingClick={handleTrackingClick}
              sanityProductsLength={sanityProducts.length}
            />
          )}
        </Box>
      </Container>
    </Box>
  ) : null
}

export default React.memo(withSection(ProductContents))
