import React from "react"
import { Box, Skeleton, Flex, IconButton } from "@chakra-ui/react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { Icon } from "@app/components/Icon"

import ProductContentsItem from "@app/components/Product/ProductContentsItem"
import { HandleTrackingClickType } from "@app/hoc/Section"
import { NormalisedProduct } from "@root/types/custom-types/Product/Product"

SwiperCore.use([Navigation])

type Props = {
  products: NormalisedProduct[]
  handleTrackingClick?: HandleTrackingClickType
  sanityProductsLength: number
}

const ProductContentsSwiper: React.FC<Props> = ({ products, handleTrackingClick, sanityProductsLength }) => {
  const id = Math.random().toString(16).slice(2)
  const prevId = `o-${id}-swiper-arrow--prev`
  const nextId = `o-${id}-swiper-arrow--next`

  const navButtonStyles = {
    d: "inline-flex",
    w: "10",
    h: "10",
    borderRadius: "full",
    bg: "background.white",
    boxShadow: "0px 2px 3px rgba(58, 21, 10, 0.1)",
    _focus: { boxShadow: "0px 2px 3px rgba(58, 21, 10, 0.1)" },
  }

  return (
    <Box pos="relative" px={{ base: "4", lg: "10" }} sx={{ ".swiper": { alignItems: "stretch" } }}>
      <Swiper
        lazy
        loop
        slidesPerView={3}
        spaceBetween={16}
        breakpoints={{
          1024: { slidesPerView: 4, spaceBetween: 32, navigation: { prevEl: `#${prevId}`, nextEl: `#${nextId}` } },
          1280: { slidesPerView: 6, navigation: { prevEl: `#${prevId}`, nextEl: `#${nextId}` } },
        }}
      >
        {products.length
          ? products.map(product => (
              <SwiperSlide key={product.id}>
                <ProductContentsItem product={product} handleTrackingClick={handleTrackingClick} />
              </SwiperSlide>
            ))
          : [...Array(sanityProductsLength)].map((i, index) => (
              <SwiperSlide key={index}>
                <Skeleton w="full" pb="178%"></Skeleton>
              </SwiperSlide>
            ))}
      </Swiper>

      <Flex
        d={{ base: "none", lg: products.length > 4 ? "flex" : "none", xl: products.length > 6 ? "flex" : "none" }}
        pos="absolute"
        top="calc(50% - 20px)"
        left="50%"
        transform="translate(-50%, -50%)"
        w={{ base: "calc(100% + 40px)" }}
        zIndex="overlay"
        justifyContent="space-between"
        pointerEvents="none"
      >
        <IconButton
          variant="unstyled"
          aria-label="Previous"
          icon={<Icon name="arrows/chevron-left" width="24" height="24" />}
          id={prevId}
          {...navButtonStyles}
          pointerEvents="initial"
        />
        <IconButton
          variant="unstyled"
          aria-label="Next"
          icon={<Icon name="arrows/chevron-right" width="24" height="24" />}
          id={nextId}
          {...navButtonStyles}
          pointerEvents="initial"
        />
      </Flex>
    </Box>
  )
}

export default React.memo(ProductContentsSwiper)
