import React from "react"
import { Box, Skeleton, Grid, GridItem } from "@chakra-ui/react"

import ProductContentsItem from "@app/components/Product/ProductContentsItem"
import { HandleTrackingClickType } from "@app/hoc/Section"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"

type Props = {
  products: NormalisedProduct[]
  handleTrackingClick?: HandleTrackingClickType
  sanityProductsLength: number
}

const ProductContentsGrid: React.FC<Props> = ({ products, handleTrackingClick, sanityProductsLength }) => (
  <Box pos="relative" px={{ base: "4", lg: "0" }}>
    <Grid templateColumns={{ base: "repeat(3, 1fr)", lg: "repeat(4, 1fr)", xl: "repeat(6, 1fr)" }} gap={{ base: "4", lg: "8" }}>
      {products.length
        ? products.map(product => {
            return (
              <GridItem key={product.id}>
                <ProductContentsItem product={product} handleTrackingClick={handleTrackingClick} />
              </GridItem>
            )
          })
        : [...Array(sanityProductsLength)].map((i, index) => (
            <GridItem key={index}>
              <Skeleton w="full" pb="178%" isLoaded={false}></Skeleton>
            </GridItem>
          ))}
    </Grid>
  </Box>
)

export default React.memo(ProductContentsGrid)
