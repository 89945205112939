import React from "react"
import { Box, AspectRatio, Image, Text } from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"
import { useProductContext } from "@app/providers/product"
import { HandleTrackingClickType } from "@app/hoc/Section"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"

type Props = {
  product: NormalisedProduct
  handleTrackingClick?: HandleTrackingClickType
}

const ProductContentsItem: React.FC<Props> = ({ product, handleTrackingClick }) => {
  const { setDrawerProduct, setIsProductDrawerOpen } = useProductContext()
  const { getGatsbyImage } = useImage()
  const productImage = getGatsbyImage(product.images[0])

  const onClickHandler = () => {
    setDrawerProduct(product)
    setIsProductDrawerOpen(true)
    if (handleTrackingClick) handleTrackingClick()
  }

  return (
    <Box as="button" w="full" onClick={onClickHandler} cursor="pointer">
      <AspectRatio ratio={1 / 1} w="full" mb={{ base: "3" }}>
        <Image {...productImage} />
      </AspectRatio>

      <Text size="mediumParagraph" color="typography.headlines900">
        {product.title}
      </Text>
    </Box>
  )
}

export default React.memo(ProductContentsItem)
